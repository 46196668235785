import React from 'react';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
    const navigate = useNavigate();
    const handleLogout = () => {
      localStorage.removeItem('token');
    localStorage.removeItem('user');
      // Redirect to LoginPage and pass state
      navigate('/', { state: { fromLogout: true } });
    };
  return (
    <div className="position-sticky">
        {/* <div className="text-center">
            <img src="../../assets/images/best-business-alliance.png" alt="BBA" className="logo" />
        </div> */}
    <div className="text-left">
        <h1 className="h3 text-success">Insurance Portal</h1>
    </div>
    <ul className="side-links">
        <li><router-link to="/">Dashboard</router-link></li>
        <li><router-link to="/member-dashboard">Insurance Plans</router-link></li>
        <li><router-link to="/member-dashboard">Customer List</router-link></li>
        <li><router-link to="/member-dashboard">Customer Policy List</router-link></li>
        <li><router-link to="/member-dashboard">Policies Near to Renewal</router-link></li>
        <li>      <button onClick={handleLogout}>Logout</button></li>
       
    </ul>
</div>
    
  );
}

export default Sidebar;
