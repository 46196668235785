import React from 'react';
import DashboardLayout from '../components/common/DashboardLayout'; // Ensure the path is correct

const Dashboard = () => {
  return (
    <DashboardLayout>
      <div className='col-12 text-center'>
        <h1>Welcome to the Dashboard</h1>
        {/* Other content goes here */}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
