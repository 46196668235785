// DashboardLayout.js

import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

const DashboardLayout = ({ children }) => {  
  return (
    <div className="container-fluid">
      <div className="row">
        <nav className="col-md-3 col-lg-2 d-md-block sidebar p-3 cursor-pointer">
          <Sidebar />
        </nav>
        <main className="col-md-9 ms-sm-auto col-lg-10 bg-light padding_none">
          <Header />
          {children} {/* Make sure this is where you want the Dashboard content to go */}
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
