import React from 'react';

function Header() {

  return (
    <div className="w-100 bg-white px-4 py-3">
    <div className="d-flex align-items-center">
      <div className="lf-t-header d-flex align-items-center">
          <router-link to="/send-new-quotation" className="badge rounded-pill bba-bg-primary p-2">Send New Quotation</router-link>
      </div>
     
    <div className="right-header">
        <ul className="rt-links">
            <li className="nav-item dropdown">
                <a className="dropdown-toggle btn-profile text-link" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                 <div className="profile">
                  <span>MK</span>
                 </div> Mohit Kumar (BTT343)
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                  <li><a className="dropdown-item" href="#">My Account</a></li>
                  <li><a className="dropdown-item" href="#">Change Password</a></li>
                  <li><hr className="dropdown-divider"/></li>
                  <li><a className="dropdown-item" href="#">Log Out</a></li>
                  
                </ul>
              </li>
        </ul>
    </div>
    </div>
</div>
    
  );
}

export default Header;
