import React, { useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { useEffect} from 'react';
import { useLocation } from 'react-router-dom';

function LoginPage() {
  const location = useLocation();
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);

  // Check if we navigated here after logging out
  useEffect(() => {
    if (location.state?.fromLogout) {
      setShowLogoutMessage(true);
      // Optionally reset location state to avoid showing the message again on refresh
      window.history.replaceState({}, document.title);
      // Hide the message after a few seconds
      const timer = setTimeout(() => setShowLogoutMessage(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [location]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState({ message: '', type: '' });
  const navigate = useNavigate(); // Create navigate function

  const showAlert = (message, type) => {
    setAlert({ message, type });
    // Optionally reset the alert after some time
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 5000); // Alert disappears after 5 seconds
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://educrmapi.amaziocraft.com/api/login', {
        email: email,
        password: password,
      });
      // If the request is successful, store the token and user details
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user)); // Assuming 'user' contains user details

      // Redirect to the dashboard
      navigate('/dashboard'); // Navigate to the dashboard

      // Show a success message
      showAlert('Login successful!', 'success');
    } catch (error) {
      // If there is an error, show an error message
      showAlert('Login failed. Please check your credentials.', 'danger');
      console.error('Login error', error.response);
    }
  };

  const leftColumnStyle = {
    backgroundColor: '#8C30F5', // Adjust the color to match the one in the image
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    padding: '0 50px'
  };

  const cardStyle = {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Add a subtle shadow to the card
  };

  return (
    <Container fluid>
      <Row className="no-gutters">
        <Col md={6} style={leftColumnStyle}>
          <h1>TO KEEP CONNECTED WITH LARGEST E-COMMERCE COMPANY IN THE WORLD</h1>
          <p>We are glad to see you again! Get access to your Orders, Wishlist and Recommendations.</p>
        </Col>
        <Col md={6} className="d-flex align-items-center justify-content-center">
          <Card style={cardStyle} className="p-5">
            <Card.Body>
               <div>
      {showLogoutMessage && (
        <div className="alert alert-success" role="alert">
          You have logged out successfully.
        </div>
      )}
      {/* The rest of the login page */}
    </div>
            {alert.message && (
                <div className={`alert alert-${alert.type}`} role="alert">
                  {alert.message}
                </div>
              )}

            <Form onSubmit={handleSubmit}>
              {/* ... other form groups ... */}
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email or Username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Update state when input changes
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Update state when input changes
                />
              </Form.Group>

                <Button variant="primary" type="submit" className="w-100 mb-3">
                  Log In
                </Button>

                <div className="text-center">
                  <a href="/forgot-password">Forgot your username or password?</a>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
